:root {
  --main: #5e88bf;
  --secondary: #1f3140;
  --tertiary: #6b98bf;
  --accentone: #0d1c26;
  --accenttwo: #617c8c;
}

.nav-item {
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.nav-link-custom {
  font-weight: 700 !important;
  font-size: 13px !important;
}

.navbar-custom {
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
}

.navbar-scrolled {
  background-color: var(--accentone) !important;
}

.active {
  color: var(--secondary) !important;
}

.navbar .sticky {
  background-color: var(--main) !important;
}

.heading-number {
  color: var(--accenttwo);
}

@media only screen and (max-width: 991px) {
  .fixed-top {
    background-color: var(--accentone) !important;
  }
}
