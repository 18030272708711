:root {
  --main: #5e88bf;
  --secondary: #1f3140;
  --tertiary: #6b98bf;
  --accentone: #0d1c26;
  --accenttwo: #617c8c;
}

.experience-background {
  padding: 60px 0px;
}

.experience-header {
  font-size: clamp(27px, 5vw, 40px);
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  gap: 15px;
}

.experience-header::after {
  content: "";
  width: 50%;
  height: 1px;
  background-color: #8892b0;
  opacity: 0.5;
}

.experience-tabs {
  display: flex;
  flex-direction: row;
}

.experience-tab-list {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.experience-tab {
  font-size: 1rem;
  font-weight: 500;
  color: #8892b0;
  padding: 12px;
  text-align: left;
  transition: all 0.2s ease-in-out;
}

.experience-panel {
  box-shadow: none;
}

.mantine-Tabs-tab[data-active="true"] {
  color: #ccd6f6 !important;
  font-weight: bold;
  border-right: 2px solid var(--main);
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .experience-tabs {
    flex-direction: column;
  }

  .experience-tab-list {
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
  }

  .experience-tab {
    border-left: none;
    border-bottom: 2px solid transparent;
  }

  .mantine-Tabs-tab[data-active="true"] {
    border-bottom: 2px solid var(--main);
    border-right: none;
  }

  .mantine-Tabs-tabsList {
    justify-content: center;
  }
}
